import { useState } from "react";

export function Header() {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const menu = [
    /* { text: "What we do?", link: "#what-we-do" }, */
    /* { text: "Who we are?", link: "#who-we-are" }, */
    /* { text: "About", link: "#" }, */
    { text: "Contact us", link: "mailto:hi@pikisoft.nl" },
  ];

  return (
    <header className="">
      <nav className="container flex items-center justify-between p-6 mx-auto">
        <div className="flex-col items-center hidden w-full sm:flex lg:flex-row gap-2">
          <div className="items-center hidden sm:flex flex-flow lg:flex lg:flex-row gap-2">
            <img
              src="./4x/Logo.png"
              alt="Logo"
              className="h-12 border rounded shadow-md"
            />
            <div className="ml-2 text-lg lg:text-2xl">Software Services</div>
          </div>
          <div className="hidden sm:flex lg:ml-auto space-x-6 whitespace-nowrap ">
            {menu.map(({ text, link }) => (
              <div className="flex items-baseline gap-2 group">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="4"
                  viewBox="0 0 12 4"
                  fill="white"
                  className="flex invisible group-hover:visible"
                >
                  <path d="M0,0h12v4h-12z">
                    <animate
                      accumulate="none"
                      additive="replace"
                      attributeName="opacity"
                      calcMode="linear"
                      dur="0.5s"
                      fill="remove"
                      repeatCount="indefinite"
                      restart="always"
                      values="1;0;1"
                    ></animate>
                  </path>
                </svg>
                <a
                  href={link}
                  key={text}
                  className="?hover:text-blue-400 ?hover:underline"
                >
                  {text}
                </a>
              </div>
            ))}
          </div>
        </div>
        <div className="w-full sm:hidden">
          <div className="relative flex items-center justify-center">
            <button
              className="absolute inset-0 focus:outline-none"
              onClick={() => setMenuOpen(!isMenuOpen)}
            >
              <svg className="w-6 h-6 fill-current" viewBox="0 0 24 24">
                <path
                  d="M4 6h16M4 12h16M4 18h16"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
            <a href="/" className="">
              <img
                src="./4x/Logo.png"
                alt="hero"
                className="h-12 border rounded"
              />
            </a>
          </div>
          {isMenuOpen && (
            <div>
              <ul className="flex flex-col mt-4 text-sm space-y-2">
                {menu.map(({ text, link }) => (
                  <li>
                    <a href={link} className="hover:text-gray-300">
                      {text}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </nav>
    </header>
  );
}
