import "./App.css";
import { Header } from "./Header";
import { About } from "./About";
import { Footer } from "./Footer";
import { Team } from "./Team";
import { Hero } from "./Hero";
import { Testemonial } from "./Testemonial";
import Cta from "./cta";
import Services from "./services";

function App() {
  return (
    <div className="text-white bg-slate-900 App">
      <div className="container flex flex-col px-4 mx-auto gap-2 md:px-20">
        <Header />
        <Hero />
        {/* <About /> */}
        {/* <Services /> */}
        {/* <Team /> */}
        {/*}<Testemonial /> */}
        {/* <Cta /> */}
      </div>
      <Footer />
    </div>
  );
}

export default App;
