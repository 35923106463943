export function Footer() {
  return (
    <>
      <div className="w-full px-4 mt-10 bg-black !lg:mt-60 !lg:px-0">
        <div className="container px-4 mx-auto md:px-20">
          <div className="pt-6 pb-6 lg:flex lg:pt-12 gap-8">
            <div className="flex flex-col items-start pb-8 ml-4 gap-2 lg:ml-0 lg:w-1/4 lg:pb-8 lg:pb-0">
              <p className="text-xl font-bold text-white">
                Piki Software Services
              </p>
              <div href="" className="text-sm text-gray-400">
                KvK 89018346
              </div>
              {/* <div href="" className="text-sm text-gray-400">
                Herengracht 433, 1017BR, Amsterdam
              </div> */}
            </div>
            { /*<div className="mt-2 ml-4 lg:mt-0 lg:ml-4 lg:ml-0 lg:w-1/2">
              <div className="text-white grid gap-8 grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 f-f-l">
                <div className="flex flex-col items-start">
                  <div className="pb-2 text-xl cursor-pointer lg:pb-5 font-lato">
                    Top Products
                  </div>
                  <div className="flex flex-col items-start text-sm ">
                    <div className="pb-2 cursor-pointer">Chairs</div>
                    <div className="pb-2 cursor-pointer">Tables</div>
                    <div className="pb-2 cursor-pointer">Vase</div>
                    <div className="cursor-pointer">Plants</div>
                  </div>
                </div>
                <div className="flex flex-col items-start">
                  <div className="pb-2 text-xl cursor-pointer lg:pb-5 font-lato">
                    Services
                  </div>
                  <div className="flex flex-col items-start text-sm ">
                    <div className="pb-2 cursor-pointer">Chairs</div>
                    <div className="pb-2 cursor-pointer">Tables</div>
                    <div className="pb-2 cursor-pointer">Vase</div>
                    <div className="cursor-pointer">Plants</div>
                  </div>
                </div>
              </div>
            </div>
              */}
          </div>
          <div className="flex flex-col-reverse items-center justify-between w-full py-2 text-base text-white lg:ml-0 lg:py-8 lg:flex-row font-lato">
            <div className="my-8 text-xs text-gray-400 lg:my-0">
              @2023 Piki Software Services
            </div>
            <div className="flex items-center mt-4 lg:mt-0">
              <div className="pr-5 cursor-pointer">
                <a href="https://linkedin.com/in/ihorpavlenko">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="25"
                    viewBox="0 0 24 24"
                    fill="white"
                    stroke="none"
                  >
                    <path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z" />
                    <rect x="2" y="9" width="4" height="12" />
                    <circle cx="4" cy="4" r="2" />
                  </svg>
                </a>
              </div>
              <div className="pr-5 cursor-pointer">
                <a href="https://github.com/my8bit">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="white"
                  >
                    <path d="M9 19c-5 1.5-5-2.5-7-3m14 6v-3.87a3.37 3.37 0 0 0-.94-2.61c3.14-.35 6.44-1.54 6.44-7A5.44 5.44 0 0 0 20 4.77 5.07 5.07 0 0 0 19.91 1S18.73.65 16 2.48a13.38 13.38 0 0 0-7 0C6.27.65 5.09 1 5.09 1A5.07 5.07 0 0 0 5 4.77a5.44 5.44 0 0 0-1.5 3.78c0 5.42 3.3 6.61 6.44 7A3.37 3.37 0 0 0 9 18.13V22" />
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
