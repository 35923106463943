export function Hero() {
  return (
    <div>
      <div className="relative flex flex-col">
        <h1 className="py-4 text-2xl font-bold sm:text-3xl md:text-5xl">
          Custom Software <i>Solutions</i> for Unique <i>Challenges</i>
        </h1>
        <div className="flex flex-wrap items-start justify-center lg:flex-nowrap">
          <div className="flex flex-col items-center justify-center px-4 md:px-0 md:w-1/2">
            <div className="flex flex-col mt-4 text-sm text-center text-gray-200 sm:text-base gap-8 md:p-12 kxl:w-2/3 md:text-lg lg:text-xl leading-6">
              <p>
                With core values of Innovation, Collaboration, and Reliability,
                we provide expert software development services for our
                customers.
              </p>
              <p>We specialize in building complex custom web apps.</p>
              <p className="text-base md:text-2xl">
                Whether you're just starting out or scaling up, we're here to
                help!
              </p>
              <a
                role="button"
                className="w-48 px-4 py-2 mx-auto font-bold text-white bg-blue-500 rounded shadow-md lg:text-xl hover:bg-blue-700"
                href="mailto:hi@pikisoft.nl"
              >
                Get in touch!
              </a>
            </div>
          </div>
          <div className="my-5 md:w-2/3 md:px-0 md:mb-0">
            <img src="./hero-blinking.svg" alt="hero" />
          </div>
        </div>
        {/* <div className="flex justify-center gap-4">
          <div className="flex flex-col justify-start space-y-2">
            <h3 className="text-xl font-medium 1text-blue-300 leading-5">
              10+
            </h3>
            <p className="text-sm leading-none text-gray-400">Total mentors</p>
          </div>
          <div className="flex flex-col justify-start space-y-2">
            <h3 className="text-xl font-medium 1text-blue-600 leading-5">
              20+ total courses
            </h3>
            <p className="text-sm leading-none text-gray-400">Total Courses</p>
          </div>
        </div>
        */}
      </div>
    </div>
  );
}
